<template>
	<b-row>
		<b-col cols="12" >
			<b-card>
				<b-overlay
					variant="white"
					:show="showLoading"
					spinner-variant="primary"
					blur="0"
					opacity=".75"
					rounded="sm"
				>
					<div class="mb-3">
						<div class="">
							<b-row>
								<b-col sm="12" class="pb-2">
									<h4>Informació del Rol</h4>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm="6">
									<b-form-group label="Nom" label-for="account-username">
										<b-form-input v-model="name" placeholder="Nom"
													  :class="validation.name ? 'is-invalid': ''"/>
										<small v-if="validation.name" class="text-danger"> {{ validation.name }}</small>
									</b-form-group>
								</b-col>
                <b-col sm="6">
                  <b-form-group label="Department" label-for="account-username">
                    <v-select
                        v-model="department_id"
                        label="name"
                        :reduce="(option) => option.id"
                        :options="departments"
                    />
                    <small v-if="validation.department_id" class="text-danger"> {{ validation.department_id }}</small>
                  </b-form-group>
                </b-col>
							</b-row>

							<b-row>
								<b-col sm="6">
									<b-form-group label="Tipus de Rol" label-for="type">
										<v-select
											v-model="type"
											label="name"
											name="type"
											:reduce="(option) => option.value"
											:options="roleTypes"
										/>
										<small v-if="validation.type" class="text-danger"> {{ validation.type }}</small>
									</b-form-group>
								</b-col>
                <b-col sm="6">
                  <b-form-group label="Permisos" label-for="account-username">
                    <v-select
                        v-model="permissions_role"
                        multiple
                        label="display_name"
                        :reduce="(option) => option.id"
                        :options="permissions"
                    />
                    <small v-if="validation.permissions" class="text-danger"> {{ validation.permissions }}</small>
                  </b-form-group>
                </b-col>
							</b-row>
							<b-row align-h="start">
								<b-col cols="5">
									<b-button v-if="!edit" variant="primary" class="mt-2 mr-1" @click="sendData">
										Desar Canvis
									</b-button>
									<b-button v-else variant="primary" class="mt-2 mr-1" @click="updateData">
										Actualitzar Canvis
									</b-button>
									<b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
										Cancel·lar
									</b-button>
								</b-col>
							</b-row>
						</div>
					</div>
				</b-overlay>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
import {BButton, BCard, BCol, BFormGroup, BFormInput, BOverlay, BRow} from 'bootstrap-vue'

import vSelect from "vue-select"
import {departments} from "@/constants/departments"
import {mapState} from "vuex"
import {ROLES_LIST} from "@/router/web/constants"
import {roleTypes} from "@/constants/role"
import ToastificationContent from "@core/components/toastification/ToastificationContent"

export default {
	name: "FormView",
	components: {
		BButton,
		BFormGroup,
		BFormInput,
		BRow,
		BCol,
		BCard,
		BOverlay,

		vSelect,
	},
	data() {
		return {
			name: null,
      department_id: null,
			permissions_role: [],
			showLoading: false,
			type:null,

      departments,
			roleTypes,
		}
	},
	computed: {
		...mapState('role', ['validation', 'permissions']),
	},
	created() {
		this.$store.commit('role/CLEAR_VALIDATION')
		this.getPermissions()
		if (this.$route.params.id) {
			this.edit = true
			this.getRole()
		} else {
			this.edit = false
		}
	},
	methods: {
		getPermissions(){
			this.$store.dispatch('role/getPermissions')
		},
		getRole(){
			this.showLoading = true

			this.$store.dispatch('role/getRole', {
				relations: ['permissions'],
				id: this.$route.params.id,
			})
				.then(response => {
					this.name = response.name
					this.department_id = response.department_id
					this.type = response.type
					this.permissions_role = response.permissions.map(function (role) {
						return role['id']
					})

					this.showLoading = false
				})
				.catch(error => {
					console.log(error)
					this.showLoading = false
				})
		},
		sendData() {
			this.$store.dispatch('role/createRole', {
				name: this.name,
				type: this.type,
        department_id: this.department_id,
				permissions: this.permissions_role,
			})
				.then(response => {
					this.$router.replace(ROLES_LIST)
						.then(() => {
							this.$toast({
								component: ToastificationContent,
								position: 'top-right',
								props: {
									title: `Rol creat correctament!`,
									icon: 'CheckIcon',
									variant: 'success',
								},
							})
						})
				})
				.catch(error => {
					console.log(error)
				})
		},
		updateData() {
			this.$store.dispatch('role/updateRole', {
				id: this.$route.params.id,
				name: this.name,
				type: this.type,
        department_id: this.department_id,
				permissions: this.permissions_role,
			})
				.then(response => {
					this.$router.replace(ROLES_LIST)
						.then(() => {
							this.$toast({
								component: ToastificationContent,
								position: 'top-right',
								props: {
									title: `Rol actualitzat correctament!`,
									icon: 'CheckIcon',
									variant: 'success',
								},
							})
						})
				})
				.catch(error => {
					console.log(error)
				})
		},
		cancel() {
			this.$router.push(ROLES_LIST)
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
